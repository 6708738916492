import React, { useState, useEffect } from 'react';
import dateTimeDataType from '../../types/dateTimeDataType';
import DatePicker from 'react-datepicker';
import Icon from '../Icon';

const Input = props => {
  const {
    alternateValue,
    autoComplete,
    customClass,
    customErrorMessage,
    dateFormat,
    dateTimeInputValid,
    dateTimeInputRef,
    disabled,
    icon,
    id,
    inputClassName,
    hasErrors,
    helpText,
    highlightDates,
    invisibleLabel,
    label,
    minDate,
    maxDate,
    name,
    onBlur,
    onChange,
    onInvalid,
    placeholder,
    popperModifiers,
    popperProps,
    required,
    showPopperArrow,
    showRequiredIndicator,
    showTimeSelect,
    showTimeSelectOnly,
    timeCaption,
    timeFormat,
    timeIntervals,
    testId,
    units,
    value
  } = props;

  const getDateObj = dateVal => {
    if (showTimeSelectOnly && dateVal) {
      dateVal = dateVal.replace(/[AP]/, ' $&');
      const currentDate = new Date().toDateString();
      const combined = new Date(`${currentDate} ${dateVal}`);
      return combined;
    } else if (dateVal) {
      return new Date(dateVal);
    } else return '';
  };

  const [isInvalid, setIsInvalid] = useState(hasErrors);
  const [fieldValue, setFieldValue] = useState(getDateObj(value));

  useEffect(() => {
    if (!value) {
      setFieldValue('');
      return;
    }

    const convertedValue = getDateObj(value);
    // getTime() forces js to compare dates as numbers instead of objects
    if (fieldValue && fieldValue.getTime() !== convertedValue.getTime()) {
      setFieldValue(convertedValue);
    }
  }, [value]);

  useEffect(() => {
    if (dateTimeInputValid || dateTimeInputValid === undefined) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  }, [dateTimeInputValid]);

  const handleChange = date => {
    if (onChange) onChange(date);

    setFieldValue(date);
    if (isInvalid) {
      setIsInvalid(false);
    }
  };

  const handleBlur = e => {
    if (onBlur) onBlur(e);

    // 'oninvalid' prop doesn't work with this datepicker library so we have to do it this way
    // It will still use the default browser popup on form submit, however
    if (e.target.checkValidity() === false) {
      setIsInvalid(true);

      if (onInvalid) {
        onInvalid(e);
      }
    } else {
      setIsInvalid(false);
    }
  };

  return (
    <div
      className={`${customClass ? customClass : 'mb-6 last:mb-0'} ${
        isInvalid ? 'has-error' : ''
      }`}
      data-testid={testId ? `${testId}-container` : null}
    >
      <label
        htmlFor={id}
        className={`control-label ${invisibleLabel ? 'sr-only' : ''}`}
        data-testid={testId ? `${testId}-label` : null}
      >
        {required && showRequiredIndicator && (
          <>
            <abbr title="This field is required.">*</abbr>{' '}
          </>
        )}
        {label}
        {units && (
          <span
            className="text-muted text-sm font-normal ml-2"
            data-testid={testId ? `${testId}-label-units` : null}
          >
            {units}
          </span>
        )}
        {showRequiredIndicator === false && !required && (
          <span
            className="text-muted ml-2"
            style={{ fontWeight: 'normal', fontSize: '.85em' }}
          >
            {'(optional)'}
          </span>
        )}
      </label>
      <div className="relative">
        {icon && (
          <div
            className={`absolute top-4 left-4 z-10 pointer-events-none ${
              isInvalid ? 'text-red' : 'text-brand-primary'
            }`}
          >
            <Icon name={icon} size={20} />
          </div>
        )}
        <DatePicker
          autoComplete={autoComplete ?? 'disabled'}
          selected={fieldValue}
          dateFormat={dateFormat}
          className={`${inputClassName ?? ''} ${
            icon ? 'pl-16' : ''
          } form-control`}
          id={id}
          name={name}
          placeholderText={placeholder}
          value={alternateValue ? alternateValue : null} // Use alternateValue for displaying a different value than what is in the input
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled ? 'disabled' : null}
          required={required}
          minDate={minDate}
          maxDate={maxDate}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeCaption={timeCaption}
          timeFormat={timeFormat}
          timeIntervals={timeIntervals}
          ref={dateTimeInputRef}
          highlightDates={highlightDates}
          popperProps={popperProps}
          popperModifiers={popperModifiers}
          showPopperArrow={showPopperArrow}
        />
      </div>
      {isInvalid && customErrorMessage && (
        <div
          className="help-block error-message"
          data-testid={testId ? `${testId}-errormessage` : null}
        >
          {customErrorMessage}
        </div>
      )}
      {helpText && (
        <div
          className="help-block"
          data-testid={testId ? `${testId}-helptext` : null}
        >
          {helpText}
        </div>
      )}
    </div>
  );
};

Input.defaultProps = {
  disabled: false,
  showRequiredIndicator: true,
  timeIntervals: 15,
  type: 'text'
};

Input.propTypes = {
  ...dateTimeDataType
};

export default Input;
